export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const router = useRouter();

  if (process.server) return;

  if (!userStore.IS_AUTHORIZED && to.name !== "sign-in") {
    await router.replace({ name: "sign-in" });
  }
});
